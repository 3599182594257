<!-- 审核 -->
<template>
  <div class="diseaseList">
    <!-- 封装selectForm筛选 -->
    <el-button type="primary" size="small" v-waves @click="handleAdd"
      >新增</el-button
    >
    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="admin_id" slot-scope="{ row }">
          <el-popover trigger="hover" placement="top" v-if="!row.admin_id">
            <el-tag size="medium">{{ row.openid }}</el-tag>
            <div slot="reference" class="row-wrapper">
              <el-tag type="success" effect="dark" size="mini">
                小程序端申请
              </el-tag>
            </div>
          </el-popover>
          <el-tag v-else effect="dark" size="mini">
            后台创建 ID:{{ row.admin_id }}
          </el-tag>
        </template>
        <template slot="openid" slot-scope="{ row }">
          <el-tag v-if="!row.openid" type="danger" effect="dark" size="mini">
            非小程序申请店铺
          </el-tag>
          <el-tag v-else type="success" effect="dark" size="mini">
            {{ row.openid }}
          </el-tag>
        </template>
        <!-- <template slot="state" slot-scope="scope">
           <FillingStatus :value="scope.row" />
        </template>
        <template slot="fillRate" slot-scope="scope">
          <Percentage :value="scope.row"></Percentage>
        </template> -->
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button size="mini" type="text" @click="handleEdit(row)" primary
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>
      <!-- 弹窗表单 -->
      <el-dialog
        :title="dialogName"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="dialogForm" label-width="80px">
          <el-form-item label="店铺名称">
            <el-input clearable v-model="dialogForm.store_name"></el-input>
          </el-form-item>
          <el-form-item label="店铺地址">
            <el-input
              type="textarea"
              clearable
              v-model="dialogForm.store_addr"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input clearable v-model="dialogForm.boss_name"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input clearable v-model="dialogForm.store_phone"></el-input>
          </el-form-item>
          <el-form-item label="店长ID">
            <el-input clearable v-model="dialogForm.openid"></el-input>
          </el-form-item>
          <el-form-item label="门店码">
            <el-input clearable v-model="dialogForm.erweima"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button class="addBtn" size="small" type="primary" @click="save" v-waves
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ElTable from "@/components/elTable/index.vue";
import listHeader from "./listHeader";
import filterQueryFormItems from "./filterQueryFormItems";
import Percentage from "@/components/filters/Percentage.vue";
import FillingStatus from "@/components/filters/FillingStatus.vue";
import KindTab from "@/components/tab/kindTab/index.vue";
import { mapState } from "vuex";
import {
  addStore,
  selectStore,
  updateStore,
  deleteStore,
} from "@/services/mall/store";
export default {
  components: {
    ElTable,
    Percentage,
    FillingStatus,
    KindTab,
  },
  async created() {
    this.getListData();
  },
  data() {
    return {
      loading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      /* 表格数据 */
      tableData: [],
      /* 弹窗 */
      dialogName: "",
      dialogVisible: false,
      dialogForm: {},
      adminList: [
        { id: 0, name: "暂未开通" },
        { id: 1, name: "vip" },
        { id: 2, name: "svip" },
      ],
    };
  },
  computed: {
    filterQueryFormItems,
    listHeader,
    ...mapState(["userInfo"]),
  },
  watch: {},
  methods: {
    async getListData(listQuery) {
      this.loading = true;
      const { data, code, count } = await selectStore(
        listQuery || this.listQuery
      );
      // console.log("============getListData===============", data);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    /* 搜索按钮 */
    async search(listQuery) {
      console.log("listQuery", listQuery);
      this.listQuery = listQuery;
      this.getListData();
    },

    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },
    /* 新增 */
    handleAdd() {
      this.dialogVisible = true;
      this.dialogName = "创建门店";
      this.dialogForm = {};
    },

    /* 通知续费 */
    send() {
      this.$message({
        type: "error",
        message: "该功能暂未开通!",
      });
    },
    /* 修改 */
    handleEdit(row) {
      console.log(row);
      this.dialogVisible = true;
      this.dialogName = "编辑门店";
      this.dialogForm = JSON.parse(JSON.stringify(row));
    },
    /* 保存 */
    save() {
      if (this.dialogName === "创建门店") {
        addStore({ ...this.dialogForm, admin_id: this.userInfo.id }).then(
          (res) => {
            console.log("创建门店", res);
            if (res.code === 200) {
              this.listQuery.pageNumber = 1;
              this.getListData();
              this.$message({
                type: "success",
                message: "创建成功!",
              });
              this.dialogVisible = false;
            }
          }
        );
      }
      if (this.dialogName === "编辑门店") {
        updateStore(this.dialogForm).then((res) => {
          if (res.code === 200) {
            this.getListData();
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.dialogVisible = false;
          }
        });
      }
    },
    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm(
        `此操作将永久删除'${row.store_name}'店铺吗, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteStore({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.diseaseList {
  width: 100%;
}
.selectForm {
  background: #fff;
  // max-width: 1405px;
  padding: 10px 0;
  margin: 10px auto;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}

/* 列气泡 */
.row-wrapper:hover {
  cursor: pointer;
}
</style>
