export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 80 },
    { label: "创建人ID", prop: "admin_id", slots: "admin_id", width: 120 },
    { label: "店铺ID", prop: "store_id", width: 60 },
    { label: "店铺名称", prop: "store_name", width: 130 },
    { label: "地址", prop: "store_addr" },
    // { label: "店长微信openid", prop: "openid", slots: "openid" },
    { label: "店长", prop: "boss_name", width: 50 },
    { label: "手机号", prop: "store_phone" },
    // { label: "等级", prop: "grade", slots: "grade" },
    {
      label: "创建时间",
      prop: "create_time",
      width: 180,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 150,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
